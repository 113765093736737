import React, {useContext, useEffect, useState} from 'react';
import {Button, InlineLoading} from 'carbon-components-react';
import {ModalEntity, TextField, TextAreaField} from '../components/carbon-entitysync'; 
import { HeedContext } from '../gatsby-theme-carbon/components/Layout';
import validateEmail from '../util/validateEmail'

const LorModal = ({question, setRequests, disabled, setLocked, locked, open, setOpen, setError}) => {
  const heed = useContext(HeedContext)

  const my_requests = heed.requests?.loaded && heed.requests.loaded.length > 0 ? heed.requests.loaded.filter((request) => {
    const rel_question = typeof request.relationships?.field_lor_question !== 'undefined' && request.relationships?.field_lor_question?.data !== null ? request.relationships?.field_lor_question?.data : null
    return rel_question && rel_question.id === question.drupal_id
  }) : []

  const requestCount = my_requests.length

  const [errors, setErrors] = useState([])
  const [emailInvalidText, setEmailInvalidText] = useState('')
  const [initialLorsCount] = useState(my_requests.length);

  // To close modal form
  useEffect(() => {
    if(my_requests.length != initialLorsCount)
      setOpen(false);
  }, [heed.requests?.loaded]);


  function checkDuplicateEmail(email){
    if(heed.requests?.loaded){
      let find = heed.requests.loaded.filter((rq) => rq.attributes.field_request_email.toLowerCase() === email.toLowerCase())
      // console.warn('FINDING DUPLICATE EMAIL', find, email.toLowerCase());
      if(find && find.length > 0) return true
    }
    return false
  }

  const onModalSubmit = async (e, unsavedChanges, entityData) => {
    if (unsavedChanges.attributes) {
      // console.warn('SENDING LOR REQ', unsavedChanges);
      let fields_to_validate = ['field_request_email', 'field_personal_message']
      let invalid_fields = []
      for (const a_field of fields_to_validate) {
        if(typeof unsavedChanges.attributes[a_field] === 'undefined' ||  unsavedChanges.attributes[a_field].length === 0){
          invalid_fields.push(a_field)
        }else if(typeof unsavedChanges.attributes[a_field] === 'string'){
          const field_is_valid = a_field.includes('email') ? validateEmail(unsavedChanges.attributes[a_field]) : unsavedChanges.attributes[a_field].length > 0 && unsavedChanges.attributes[a_field].length <= 2000;
          if(!field_is_valid){
            invalid_fields.push(a_field)
            if(a_field.includes('email')){
              setEmailInvalidText('Please add a valid email address')
            }
          }else if(a_field.includes('email') && field_is_valid && checkDuplicateEmail(unsavedChanges.attributes[a_field])){
            invalid_fields.push(a_field)
            setEmailInvalidText('You already sent a request to this email address')
          }
        }else{
          invalid_fields.push(a_field)
        }
      }
      setErrors(invalid_fields)
      if(invalid_fields.length > 0){
        e.preventDefault()
      }else{
        unsavedChanges.attributes.title = `Letter of Recommendation Request by ${heed?.user.attributes.field_full_name} for ${unsavedChanges.attributes.field_request_email}`
        unsavedChanges.attributes.field_applicant_name = heed?.user.attributes.field_full_name
        if(typeof unsavedChanges.relationships === 'undefined'){
          unsavedChanges.relationships = {}
        }
        unsavedChanges.relationships.field_lor_question = {data: {type: 'node--lor_question', id: question.drupal_id}}
        setLocked('requesting')
      }
    }
  }

  const disable_modal_button = parseInt(requestCount) === parseInt(question.field_number_of_letters) || disabled

  return (
    <>
      
      <Button onClick={(e) => {
        setErrors([]);
        setOpen(true);
      }} kind={disable_modal_button ? 'secondary' : 'tertiary'} disabled={disable_modal_button}>
          Send request
      </Button>
      <ModalEntity
        type="node--lor_request"
        componentId="lor-request"
        modalHeading="Request Letter of Recommendation"
        primaryButtonText={disabled ? <InlineLoading status='active' /> : "Send Request"}
        open={open}
        onRequestClose={() => {
          if(!disabled){
            setOpen(false)
          }
        }}
        onSubmit={onModalSubmit}
        disabled={disabled}
      >
        <form>
          <TextField light
            field="field_request_email"
            labelText="Email:"
            placeholder="Email"
            onChange={(e) => {setErrors([])}}
            defaultValue="" 
            disabled={disabled}
            invalid={errors.includes('field_request_email')}
            invalidText={emailInvalidText}
          /> 
          <br />
          <TextAreaField light
            charLimit={2000}
            placeholder="Message to be sent with request"
            onChange={(e) => {setErrors([])}}
            field="field_personal_message" 
            disabled={disabled}
            invalid={errors.includes('field_personal_message')}
            invalidText="Please add a personal message"
          />
        </form>
      </ModalEntity>
    </> 
  )
}

export default LorModal
