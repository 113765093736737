import React, { useContext } from 'react'
import {AddressField} from './carbon-entitysync'
import { EntityContext } from '@parallelpublicworks/entitysync';
import DependsOn from './DependsOn';

function AddressQuestion({field, 
  question, 
  error, 
  setError, 
  setTouched, 
  touched, 
  required, 
  disabled = false
}) {

  const validate = (value) => {
        if(!touched && value && value.length > 0 && setTouched) setTouched(true)
        value = typeof value === 'undefined' ? {} : value;
        delete value.field_address_line2
        if(question.field_exclude_fields && question.field_exclude_fields.length > 0){
            for (const exclude_key of question.field_exclude_fields) {
                delete value[exclude_key]
            }
        }
        let valid = true;
        let address_keys = Object.keys(value);
        if(address_keys.length === 0){
            valid = false;
        }else{
            for (const address_key of address_keys) {
                switch (address_key) {
                    case "field_country":
                        valid = value[address_key] === 'US';
                        break;
                    default:
                        valid = value[address_key] && `${value[address_key]}`.length > 0;
                        break;
                }
                if(!valid) break;
            }
        }
        if(setError && required){
            if(!valid){
                setError('Please complete this field')
            }else{
                setError(false)
            }
        }
    }

    let { field_depends_on, field_depends_on_option } = question.relationships;

    return <div className="task-question">
              <DependsOn
                dependsOn={field_depends_on}
                dependsOnOptions={field_depends_on_option}
              >
                <AddressField 
                  field={field} 
                  question={question}
                  onChange={validate}
                  labelText={`${question.title} ${required ? '' : '(optional)'}`} 
                  invalidText={!!error && touched ? error : ''} 
                  invalid={!!error && touched}
                  disabled={disabled}
                  required={required} />
              </DependsOn>
            </div>;
}

export default AddressQuestion;
