import React, { useContext, useEffect } from 'react';
import { EntityContext } from '@parallelpublicworks/entitysync';

export default function DependsOn({ dependsOn, dependsOnOptions, removeFieldError, children: render }) {
    
  const entityContext = useContext(EntityContext);

  if(dependsOn && entityContext) {
    const { attributes = {}, relationships = {}  } = entityContext.data;

    let answerValue = attributes[`field_${dependsOn.field_identifier}`] ||
      relationships[`field_${dependsOn.field_identifier}`];
      
      if(answerValue?.data) {
        let hasToRender = dependsOnOptions.some(option => {
          return Boolean(answerValue.data.find(answerOption => answerOption.id == option.drupal_id));
        });

      if(!hasToRender) render = <DummyComponent removeFieldError={removeFieldError} />;

    } else if(!answerValue) {
      render = <DummyComponent removeFieldError={removeFieldError} />;
    }
  }

  return (
    <>
      {render}
    </>
  )
}

const DummyComponent = ({ removeFieldError }) => {
  useEffect(()=> {
    // While the component should not display we have to remove
    // the error if there is one on this Question
    removeFieldError && removeFieldError();
  }, []);

  return <></>;
}
