import React, {useContext, useState, useRef, useEffect} from 'react'
import moment from 'moment';
import {SubmitButtonComponent, Entity} from '@parallelpublicworks/entitysync'
import {Button} from 'carbon-components-react'
import {ArrowLeft16} from '@carbon/icons-react'
import { graphql, navigate } from 'gatsby'
import LorModal from '../components/LorModal'
import TextQuestion from '../components/TextQuestion'
import AddressQuestion from '../components/AddressQuestion'
import CheckboxQuestion from '../components/CheckboxQuestion'
import FormattedText from '../components/FormattedText'
import FileQuestion from '../components/FileQuestion'
import ReactHtmlParser from 'react-html-parser'
import ProgressBar from '../components/ProgressBar';
import Deadline from '../components/Deadline';
import TasksNav from '../components/TasksNav';
import {InlineNotification} from 'gatsby-theme-carbon';
import {HeedContext} from '../gatsby-theme-carbon/components/Layout';
import setEntitySource from '../util/setEntitySource'
import slugify from '../util/slugify'
import { InlineLoading } from 'carbon-components-react'
import LorDisplay from '../components/LorDisplay'

const SubmitButton = SubmitButtonComponent(({onClick, disabled, buttonLabel, kind = "primary", id, ...props}) => {
  return <Button onClick={(e) => onClick(e)} kind={kind} disabled={disabled} id={id}>{buttonLabel}</Button>
});

function LorQuestion({question, disabled = false, heed, setLocked, locked, open, setOpen, error, setError, setTouched, touched}) {
  const numberOfLetters = question.field_number_of_letters ? question.field_number_of_letters : 2
  return (<div className="task-question">
      <p>{`You will not be able to view the content of the Letter. You may request up to ${numberOfLetters} letters.`}</p>    
      <hr />
      <LorModal disabled={disabled} question={question} setLocked={setLocked} locked={locked} open={open} setOpen={setOpen} setError={setError} />
      
      <LorDisplay error={error} question={question} />
    </div>) 
}

export default function TaskTemplate({pageContext, location, data}) {
  
  const task = data.task;
  const questions = task.relationships.field_questions;
  const [open, setOpen] = useState(false)
  const [touched, _setTouched] = useState({})
  const touchedRef = useRef(touched)
  const [lastChanged, setLastChanged] = useState(null);
  const [errors, _setErrors] = useState({})
  const errorsRef = useRef(errors)
  const [submissionInvalid, setSubmissionInvalid] = useState(false)
  const [taskCompleted, setTaskCompleted] = useState(false)
  const [source, setSource] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [locked, setLocked] = useState(false)
  const [triggerSave, setTriggerSave] = useState(false)
  const [taskHasLOR, setTaskHasLOR] = useState(false)
  const [numberOfReceivedLetters, setNumberOfReceivedLetters] = useState(0)
  const [conditional, setConditional] = useState(false)
  const [natural, setNatural] = useState(false)

  pageContext = {...pageContext, frontmatter: {title: "Application Task", tabs: ['Profile','Application'], slug: '/task'}};
  const heed = useContext(HeedContext);

  // console.warn('HEED', heed);
  
  useEffect(() => {
    const lor_tasks = questions.filter((question) => typeof question.internal !== 'undefined').filter((question, index) => question.internal?.type === 'node__lor_question')
    const has_lor = typeof lor_tasks !== 'undefined' && Array.isArray(lor_tasks) && lor_tasks.length > 0
    //console.warn('TASK HAS LOR?', lor_tasks, has_lor);
    setTaskHasLOR(has_lor)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let _dis = locked === true || typeof locked === 'string' || taskCompleted;
    setDisabled(_dis);
  }, [taskCompleted, locked])


  useEffect(() => {
    if(!heed?.loadingAnswers){
      let completed = heed?.answers?.completed_tasks?.includes(task.drupal_id);
      // console.log('task completed?', heed?.answers?.completed_tasks);
      setTaskCompleted(completed);
      let the_source;
      if(heed?.answers && heed?.answers.answers){
        setSource(setEntitySource(heed.answers.answers))
      }else if(source === null){
        setSource(the_source)
      }
      // console.warn('CONTEXT CHANGED, locked?', locked, 'completed?', completed);
      if(completed && locked === 'completing'){
        setLocked(false)
      }else if(!completed && locked === 'reopening'){
        setLocked(false)
      }else if(locked === 'saving' && heed?.answers?.answers?.attributes?.changed){
        if((typeof lastChanged === 'string' || lastChanged === null) && typeof heed?.answers?.answers?.attributes?.changed === 'string'){
          // console.warn('CAN UNLOCK NOW?', lastChanged, heed?.answers?.answers?.attributes?.changed);
          if(lastChanged !== heed?.answers?.answers?.attributes?.changed){
            setLocked(false)
          }
        }
      }else if(!completed && locked === 'requesting'){
        if(Array.isArray(heed.requests?.loading)){
          if(heed.requests.loading.length){
            // console.warn('SAVING REQ...');
            setLocked('saving_request')
          }
        }
      }else if(!completed && locked === 'saving_request'){
        if(heed.requests.loading.length === 0){
          // console.warn('FINISHING...');
          setLocked(false)
          setTriggerSave(true)
        }
      }
      let _received_letters = heed?.requests?.received ? heed.requests.received.length : 0
      setNumberOfReceivedLetters(_received_letters);
    }else if(typeof heed?.answers?.answers === 'undefined'){
      setSource(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heed])

  useEffect(() => {
    if(triggerSave && !locked){
      setOpen(false)
      setTriggerSave(false)
      //console.warn('TRIGGERING SAVE...');
      if(typeof document !== 'undefined'){
        document.getElementById('task-save-button').click();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSave])

  if(heed?.user?.id && !heed?.user?.is_eligible){
    navigate('/')
    return <></>
  }

  const setTouched = (val) => {
    touchedRef.current = val
    _setTouched(val)
  }

  const setErrors = (val) => {
    errorsRef.current = val
    _setErrors(val)
  }

  const setHasError = (value = 'Invalid field', id = '') => {
    if(typeof value === 'string') {
      setErrors({
        ...errorsRef.current,
        [id]: value
      })
    } else {
      let previousErrors = {...errorsRef.current}
      delete previousErrors[id]
      setErrors(previousErrors)
    }
  }

  const setIsTouched = (value = false, id = '') => {
    if(value) {
      setTouched({
        ...touchedRef.current,
        [id]: true
      })
    } else {
      let prevTouched = {...touchedRef.current}
      delete prevTouched[id]
      setTouched(prevTouched)
    }
  }


  function parseRequestsRel (reqs) {
    return reqs.map((r) => ({
      id: r.id,
      type: 'node--lor_request'
    }))
  }

  function scrollToTop(){
    if(typeof window !== 'undefined'){
      setTimeout(() => {        
        window.scrollTo({
          top: 340,
          left: 0,
          behavior: 'smooth'
        });
      }, 100);
    }
  }


  const onSave = async (e, unsavedChanges, entityData) => {
    if(unsavedChanges){
      // console.warn('ASKED TO SAVE');

      if(taskHasLOR){
        if(heed.requests?.loaded && heed.requests.loaded.length > 0){
          const rels = parseRequestsRel(heed.requests.loaded)
          // console.warn('SAVING LOR REQ RELATIONSHIPS', heed.requests.loaded, rels);
          if(!unsavedChanges.relationships) unsavedChanges.relationships = {}
          unsavedChanges.relationships.field_letters_of_recommendation = {
            data: rels
          }
        }else{
          console.warn('NOT SAVING LOR REQ RELATIONSHIPS', heed.requests);
        }
        unsavedChanges.save = 'cache'
      }


      if(typeof unsavedChanges.save !== 'undefined' && unsavedChanges.save === false){
        // console.warn('NOTHING TO SAVE');
        e.preventDefault();
      }else{
        // console.warn('SAVING...', heed.requests);
        if(!unsavedChanges.attributes) unsavedChanges.attributes = {}
        unsavedChanges.attributes.title =  `Answers by ${heed?.user.attributes.field_full_name} - ${moment().toISOString(true)}`;

        if(entityData?.attributes?.changed){
          // console.warn('SETTING LAST CHANGED', entityData.attributes.changed);
          setLastChanged(entityData.attributes.changed);
        }else{
          // console.warn('NOT SETTING LAST CHANGED');
        }

        setLocked('saving')
      }
    }else{
      e.preventDefault();
    }
  }

  const onReopen = async (e, unsavedChanges, entityData) => {
    if(unsavedChanges){
      if(!unsavedChanges.relationships) unsavedChanges.relationships = {}
      let current_completed_tasks = []
      if(entityData.relationships?.field_completed_tasks?.data){
        if(Array.isArray(entityData.relationships.field_completed_tasks.data)){
          current_completed_tasks = entityData.relationships.field_completed_tasks.data
        }
      }
      current_completed_tasks = current_completed_tasks.filter((a_task) => a_task.id !== task.drupal_id)
      unsavedChanges.relationships.field_completed_tasks = {
        data: current_completed_tasks
      }
      setLocked('reopening')
    }
  }
  const onSubmit = async (e, unsavedChanges, entityData) => {
    if(unsavedChanges){
      
      if(taskHasLOR && numberOfReceivedLetters === 0){
        // console.warn('INVALID LOR, numberOfReceivedLetters?', numberOfReceivedLetters);
        e.preventDefault();
        setSubmissionInvalid(true);
      }else if(Object.keys(errors).length > 0){
        e.preventDefault();
        setSubmissionInvalid(true);
        for (const q of questions) {
          setIsTouched(true, q.drupal_id);
        }
      }else{
        if(!unsavedChanges.attributes) unsavedChanges.attributes = {}
        unsavedChanges.attributes.title =  `Answers by ${heed?.user.attributes.field_full_name} - ${moment().toISOString(true)}`;
        
        if(!unsavedChanges.relationships) unsavedChanges.relationships = {}
        let current_completed_tasks = []
        if(entityData.relationships?.field_completed_tasks?.data){
          if(Array.isArray(entityData.relationships.field_completed_tasks.data)){
            current_completed_tasks = entityData.relationships.field_completed_tasks.data
          }
        }
        unsavedChanges.relationships.field_completed_tasks = {
          data: [...current_completed_tasks, { id: task.drupal_id, type: 'node--task' }] 
        }

        if(taskHasLOR){
          if(heed.requests?.loaded && heed.requests.loaded.length > 0){
            const rels = parseRequestsRel(heed.requests.loaded)
            // console.warn('SAVING LOR REQ RELATIONSHIPS', heed.requests.loaded, rels);
            if(!unsavedChanges.relationships) unsavedChanges.relationships = {}
            unsavedChanges.relationships.field_letters_of_recommendation = {
              data: rels
            }
          }else{
            console.warn('NOT SAVING LOR REQ RELATIONSHIPS', heed.requests);
          }
          unsavedChanges.save = 'cache'
        }

        if(unsavedChanges.relationships) {
          unsavedChanges = cleanEmptyRelationships(unsavedChanges);
        }
        
        unsavedChanges = removeTextAreasBreaklines(unsavedChanges);
        
        setLocked('completing')
        setSubmissionInvalid(false)
      }
      scrollToTop()
      
    }else{
      setSubmissionInvalid(true)
      e.preventDefault();
    }
  }

  const cleanEmptyRelationships = (unsavedChanges) => {
    Object.keys(unsavedChanges.relationships).forEach(key => {
      if (Array.isArray(unsavedChanges.relationships[key].data)) {
        unsavedChanges.relationships[key].data.forEach((option, index) => {
          if(!option.id) {
            unsavedChanges.relationships[key].data.splice(index, 1);
          }
        });
      }
    });
    return unsavedChanges;
  }

  const removeTextAreasBreaklines = (unsavedChanges) => {
    const { attributes } = unsavedChanges;
    Object.keys(attributes).forEach(key => {
      let index = questions.findIndex(question=> question.field_type == "textarea" && question.field_identifier == key.replace("field_", ""));
      if(index >= 0) {
        attributes[key] = attributes[key].replace(/[\r\n]/gm, '');
      }
    });
    unsavedChanges.attributes = attributes;
    return unsavedChanges;
  }

  //console.warn('SOURCE?', source);

  if(source === null || heed.loadingAnswers){
    return  <InlineLoading description="Loading..." status='active' />
  }
  return (
    <div className={`application-wrapper task ${taskCompleted ? 'completed' : ''}`}>
      <div className="bx--row">
        <div id="left-panel" className="bx--col-lg-4">
          <div>
            <Button kind="tertiary" onClick={(e) => navigate('/application')}>
              <ArrowLeft16 /> <span>Back to application</span>
            </Button>
            <TasksNav current={task.drupal_id} />
            <ProgressBar />
            <Deadline page="task" />
            {heed?.answers?.all_done &&  <div className="button-group">
              <Button kind="primary" disabled={!heed?.answers?.answers?.attributes} onClick={(e) => navigate('/review')}>Review and Submit</Button>
            </div>}
          </div>
        </div>
        <div id="right-panel" className="bx--col-lg-8">
          <div>
            <div id="application-title" className="padd">
              <h2>{task.title}</h2>
            </div>
            <div id="task-description" className="padd txt">
              {taskCompleted && <InlineNotification kind="success"><p>Congratulations on completing this task!</p> {!heed?.answers?.submitted && <Entity type="node--answers" source={source} componentId="task-entity"><div className="button-reopen"><SubmitButton onSubmit={onReopen} kind="ghost" disabled={locked === true || typeof locked === 'string'} buttonLabel={`Edit Answers`} id="task-reopen-button"  /></div></Entity>}</InlineNotification>}
              {task.field_description ? ReactHtmlParser(task.field_description.value) : <></>}
              {Object.keys(errors).length > 0 && submissionInvalid && <InlineNotification kind="error"><p>Please review the invalid fields</p></InlineNotification>}
              {(taskHasLOR && numberOfReceivedLetters === 0) && submissionInvalid && <InlineNotification kind="error"><p>You need to receive at least one letter of recommendation</p></InlineNotification>}
            </div>
            <Entity type="node--answers" source={source} componentId="task-entity">
              <div id="questions" className="padd">
                {questions.filter((question) => typeof question.internal !== 'undefined').map((question, index) => {
                  const is_required = question.field_is_required === true;
                  switch (question.internal?.type) {
                    case 'node__text_question':
                      return (
                        <TextQuestion
                          key={question.drupal_id}
                          type={question.field_type}
                          setError={value => setHasError(value, question.drupal_id)}
                          setTouched={value => setIsTouched(value, question.drupal_id)}
                          error={errors[question.drupal_id]}
                          touched={touched[question.drupal_id] === true}
                          question={question}
                          field={`field_${question.field_identifier}`}
                          disabled={disabled}
                          required={is_required}
                          dependson={question.relationships.field_depends_on}
                          conditional={conditional}
                          natural={natural}
                        />
                      )
                    case 'node__checkbox_question':
                      return (
                        <CheckboxQuestion
                          key={question.drupal_id}
                          setError={value => setHasError(value, question.drupal_id)}
                          setTouched={value => setIsTouched(value, question.drupal_id)}
                          error={errors[question.drupal_id]}
                          touched={touched[question.drupal_id] === true}
                          question={question}
                          field={`field_${question.field_identifier}`}
                          disabled={disabled}
                          required={is_required}
                          dependson={question.relationships.field_depends_on}
                          conditional={conditional}
                          setConditional={setConditional}
                          natural={natural}
                          setNatural={setNatural}
                        />
                      )
                    case 'node__address_question':
                      return (
                        <AddressQuestion
                          key={question.drupal_id}
                          setError={value => setHasError(value, question.drupal_id)}
                          setTouched={value => setIsTouched(value, question.drupal_id)}
                          error={errors[question.drupal_id]}
                          touched={touched[question.drupal_id] === true}
                          question={question}
                          field={question.field_identifier}
                          disabled={disabled}
                          required={is_required}
                          conditional={conditional}
                          setConditional={setConditional}
                        />
                      )
                    case 'node__formatted_text':
                      return (
                        <FormattedText
                          key={question.drupal_id}
                          body={question.body}
                          question={question}
                        />
                      )
                    case 'node__file_question':
                      // [current-user:uid]-[current-user:name]-[current-date:custom:Y-m-d]
                      let filename;
                      if (question.field_filename_identifier) { 
                        const displayName = heed?.user?.attributes?.field_full_name ? slugify(heed?.user?.attributes?.field_full_name) : heed?.user?.id
                        const uid = heed?.user?.attributes?.drupal_internal__uid
                        const date = moment().format('YY-MM-DD')
                        filename = `${uid}-${displayName}-${date}-${question.field_filename_identifier}` 
                      }
                      
                      return (
                        <FileQuestion
                          key={question.drupal_id}
                          setError={value => setHasError(value, question.drupal_id)}
                          setTouched={value => setIsTouched(value, question.drupal_id)}
                          error={errors[question.drupal_id]}
                          touched={touched[question.drupal_id] === true}
                          question={question}
                          field={`field_${question.field_identifier}`}
                          filename={filename}
                          disabled={disabled}
                          required={is_required}
                        />
                      )
                    case 'node__lor_question':
                      return (
                        <LorQuestion 
                          key={question.drupal_id} 
                          question={question} 
                          disabled={disabled || !heed?.user?.attributes?.drupal_internal__uid || !heed?.user?.attributes?.field_full_name}
                          setLocked={setLocked}
                          locked={locked}
                          heed={heed} 
                          open={open}
                          setOpen={setOpen}
                        />
                      )
                    default:
                      return <></>
                  }
                })}
              </div>
              {!taskCompleted && <div className="button-group">
                <SubmitButton onSubmit={onSave} kind="secondary" disabled={locked === true || typeof locked === 'string'} buttonLabel={`Save Task`} id="task-save-button" />

                <SubmitButton onSubmit={onSubmit} kind="primary" disabled={locked === true || typeof locked === 'string'} buttonLabel={`Complete Task`} id="task-submit-button" />
              </div>}
            </Entity>
            
          </div>
        </div>
      </div>
    </div>    
  )
}

export const query = graphql`
  query($slug: String!) {
    task: nodeTask(fields: { slug: { eq: $slug } }) {
      id
      drupal_id
      title
      field_description {
        value
      }
      internal {
        type
      }
      relationships {
        field_questions {
          ... on node__text_question {
            drupal_id
            title
            field_is_required
            field_identifier
            field_type
            field_number_type
            field_minimum
            internal {
              type
            }
            relationships {
              field_depends_on {
                drupal_id
                field_identifier
              }
              field_depends_on_option {
                drupal_id
              }
            }
            internal {
              type
            }
          }
          ... on node__checkbox_question {
            drupal_id
            title
            field_is_multiple
            field_is_required
            field_identifier
            field_show_as_dropdown
            internal {
              type
            }
            relationships {
              field_options {
                drupal_id
                title
                field_info_text
              }
              field_invalid_options {
                drupal_id
              }
              field_depends_on {
                drupal_id
                field_identifier
              }
              field_depends_on_option {
                drupal_id
              }
            }
          }
          ... on node__address_question {
            drupal_id
            field_identifier
            field_is_required
            field_exclude_fields
            title
            internal {
              type
            }
            relationships {
              field_depends_on {
                drupal_id
                field_identifier
              }
              field_depends_on_option {
                drupal_id
              }
            }
            title
            internal {
              type
            }
          }
          ... on node__file_question {
            drupal_id
            title
            field_is_required
            field_identifier
            field_filename_identifier
            internal {
              type
            }
          }
          ... on node__formatted_text {
            drupal_id
            relationships {
              field_depends_on {
                drupal_id
                field_identifier
              }
              field_depends_on_option {
                drupal_id
              }
            }
            internal {
              type
            }
            body {
              value
            }
          }
          ... on node__lor_question {
            drupal_id
            title
            field_number_of_letters 
            internal {
              type
            }
          }
        }
      } 
    }
  }
`
