import React from 'react'
import {FileRelationship} from './carbon-entitysync'

export default function FileQuestion({question, field, filename = null, disabled = false, error, setError, setTouched, touched, required}) {

  const validate = (file) => {
    if(typeof file === 'string' && file.length > 0){
      return true
    }else if(file){
      if(typeof file.size === 'number' && file.size < 20971520) {
        if(typeof file.type === 'string'){
          if(file.type.includes('pdf')){
            return true
          } else {
            setError('Only PDF files are allowed')
            return false
          }
        }
      }
    }
    return false
  }

  const onChange = (e) => {
    if(!e){
      if(required) {
        setError('Please add a PDF file')
      }else{
        setError(false)
      }
    }else if(e && e.attributes){
      setError(false)
    }else{
      const file = typeof e === 'string' ? e : e.target ? e.target.files[0] : null
      let has_value = file ? touchField(file) : false
      let is_valid = has_value && validate(file)
      if(setError && required) {
        if(is_valid){
          setError(false)
        }else{
          setError('Please add a PDF file')
        }
      }
      if (!is_valid) {
        return; // Prevent further processing if the file is not valid
      }
    }
  }

  const touchField = (file) => {
    setTouched(true)
    let has_value = !!file
    return has_value
  }

  const invalid = !!error && touched
  const invalidText = !!error && touched ? error : ''

  return (
    <div className={`task-question file-field ${invalid ? 'invalid' : ''}`}>
      <p>{question.title} {required ? '' : ''}</p>
      <FileRelationship light field={field} filename={filename} disabled={disabled} onChange={onChange} invalid={invalid} />
      {invalid && <div className="bx--form-requirement" id={`error-file-${question.field_filename_identifier}`}>{invalidText}</div>}
    </div>
  );
}
  
