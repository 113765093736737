import React, { useContext } from 'react'
import { EntityContext } from '@parallelpublicworks/entitysync'
import {CheckboxField, SelectField } from './carbon-entitysync'
import DependsOn from './DependsOn';

export default function CheckboxQuestion({
  field, 
  question, 
  setError, 
  setTouched, 
  error, 
  touched, 
  required, 
  disabled = false, 
  setConditional,
  natural,
  setNatural}) {

  const options = question.relationships?.field_options ? question.relationships?.field_options : [];
  const questions_count = options.length;
  const invalid_options = question.relationships?.field_invalid_options ? question.relationships?.field_invalid_options.map((iop) => iop.drupal_id) : [];
  
  const onChange = (value = null) => {
    let has_value = touchField(value)
    let is_valid = has_value && validate(value)
    let optionId = value.map((val) => val.id)
    if(setError && required){
      if (window.location.pathname.includes('/application/task-1-applicant-information')
      ) {
        setConditional(optionId[0] ? optionId[0] : null)
        setError(false)
      }
      if (window.location.pathname.includes('/application/task-2-other-information')
      ) {
        setNatural(optionId[0] ? optionId[0] : null)
        setError(false)
      }
      if(is_valid){
        setError(false)
      } else {
        if(invalid_options.length && 
          (question.field_identifier.includes("eligibility") 
          || question.field_identifier.includes("natcitconfirm"))){
          setError("Only U.S. citizens, including naturalized citizens, are eligible to apply. This concludes your application. Contact heed@aao.org with questions.")
        }else if(invalid_options.length){
          setError("Select a valid option")
        }else{
          setError("Select at least one option")
        }
      }
    }
  }

  const validate = (value) => {
    if(invalid_options.length){
      const plain_val = value.map((val) => val.id)
      const is_valid_val = invalid_options.every(iop=> !plain_val.includes(iop));
      return is_valid_val;
    }else{
      return true;
    }
  }

  const touchField = (value) => {
    let has_value = (Array.isArray(value) && value.length > 0 ) || (!Array.isArray(value) && value && value.length > 0);
    // if(!touched && has_value && setTouched) setTouched(true)
    if (!touched && has_value && setTouched) {
      setTouched(true)
    }  
    return has_value;
  }

  let { field_depends_on, field_depends_on_option } = question.relationships;

  return (
    <div className={`task-question ${questions_count > 2 ? 'multiline' : ''}`}>
      <DependsOn
        dependsOn={field_depends_on}
        dependsOnOptions={field_depends_on_option}
        removeFieldError={() => setError(false)}
      >
        {
          question.field_show_as_dropdown ? (
            <SelectField
              field={field}
              question={question}
              onChange={onChange}
              invalidText={!!error && touched ? error : ''} 
              invalid={!!error && touched}
              relType="node--option"
              disabled={disabled}
              required={required}
              isRelationship
            />
          ) : (
            <CheckboxField
              field={field}
              question={question}
              onChange={onChange}
              invalidText={!!error && touched ? error : ''} 
              invalid={!!error && touched}
              relType="node--option"
              disabled={disabled}
              required={required}
            />
          )
        }
      </DependsOn>
    </div>
  )
}