import { EntityContext } from '@parallelpublicworks/entitysync';
import React, { useContext } from 'react'
import DependsOn from './DependsOn';

function FormattedText({body, question }) {

  let { field_depends_on, field_depends_on_option } = question.relationships;

    if(!body?.value){
        return <></>;
    }
    return (
      <DependsOn
      dependsOn={field_depends_on}
      dependsOnOptions={field_depends_on_option}
    >
      <div className="formatted-text" dangerouslySetInnerHTML={{__html: body.value}} />
    </DependsOn>
    )
}

export default FormattedText
